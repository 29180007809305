define("discourse/plugins/discourse-animated-avatars/app/helpers/animated-bound-avatar", ["exports", "@ember/template", "discourse/helpers/bound-avatar", "discourse/lib/utilities"], function (_exports, _template, _boundAvatar, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(user, size) {
    const avatar = (0, _boundAvatar.default)(user, size);
    if (!user.animated_avatar || (0, _utilities.prefersReducedMotion)()) {
      return avatar;
    }
    return (0, _template.htmlSafe)(avatar.toString().replace(/\.png/, ".gif"));
  }
});